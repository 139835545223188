import '@mrhenry/wp--bugsnag-config';

import MrDeltaTime from '@mrhenry/wp--mr-delta-time';
customElements.define( 'mr-delta-time', MrDeltaTime );

import './blocks/blocks.js';

import './checklist/index';

import './modules/accordion';
import './modules/mr-fly-out-menu';
import './modules/mr-fly-out-menu-trigger';
import './modules/sticky-bookmark';
import './modules/sticky-suggestions';
import './modules/top-of-page-watcher';
