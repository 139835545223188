/*
 * Make sure we show the sticky suggestions only when the users scrolls up
 * ⚠️ This works in combination with Sticky Bookmark it's 'is-scrolled-between-header-and-footer' class
 */

( function() {
	let init = () => {
		if ( 'interactive' !== document.readyState && 'complete' !== document.readyState ) {
			return;
		}

		const body = document.body;
		const stickySuggestions = document.querySelector( '.js-sticky-suggestions' );

		const scrollClass = 'is-scrolled-up';

		if ( !body ) {
			return;
		}

		if ( !stickySuggestions ) {
			return;
		}

		if ( window.matchMedia( '(max-height: 540px)' ).matches ) {
			return;
		}

		if ( window.matchMedia( '(max-width: 1279px)' ).matches ) {
			return;
		}

		init = () => {};

		let scrollState = {
			position: window.pageYOffset,
		};

		let scrollThrottle = false;

		// Event listener
		window.addEventListener( 'scroll', () => {
			if ( scrollThrottle ) {
				return;
			}

			scrollThrottle = true;
			scrollState = scrollHandler( body, scrollClass, scrollState );

			setTimeout( () => {
				scrollState = scrollHandler( body, scrollClass, scrollState );
				scrollThrottle = false;
			}, 96 );
		} );
	};

	document.addEventListener( 'readystatechange', () => {
		init();
	}, false );

	init();
}() );

const scrollHandler = ( body, scrollClass, scrollState ) => {
	const position = window.pageYOffset;

	if ( isNaN( position ) ) {
		return scrollState;
	}

	if ( 30 > Math.abs( position - scrollState.position ) ) {
		return scrollState;
	}

	if ( position < scrollState.position ) {
		addScrollClass( body, scrollClass );
	} else {
		removeScrollClass( body, scrollClass );
	}

	scrollState.position = position;

	return scrollState;
};

const addScrollClass = ( body, scrollClass ) => {
	if ( window.location.hash ) {
		return;
	}

	if ( body.classList && !body.classList.contains( scrollClass ) ) {
		body.classList.add( scrollClass );
	}
};

const removeScrollClass = ( body, scrollClass ) => {
	if ( body.classList && body.classList.contains( scrollClass ) ) {
		body.classList.remove( scrollClass );
	}
};
