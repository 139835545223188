let tableauLoader = null;
const loadTableauJS = () => {
	if ( 'tableau' in window ) {
		return Promise.resolve();
	}

	if ( tableauLoader ) {
		return tableauLoader;
	}

	tableauLoader = new Promise( ( resolve, reject ) => {
		const script = document.createElement( 'script' );
		script.src = 'https://public.tableau.com/javascripts/api/tableau-2.3.0.min.js';

		script.onload = () => {
			resolve();

			return;
		};

		script.onerror = () => {
			reject( new Error( 'failed to load tableau script' ) );

			return;
		};

		script.defer = true;
		script.referrerpolicy = 'no-referrer';

		const first = document.head.getElementsByTagName( 'script' )[0];
		first.parentNode.insertBefore( script, first );
	} );

	return tableauLoader;
};

let tableauIntersectionObserver;
if ( 'IntersectionObserver' in window ) {
	tableauIntersectionObserver = new IntersectionObserver( ( entries ) => {
		for ( let i = 0; i < entries.length; i++ ) {
			const entry = entries[i];
			if ( 0.001 >= entry.intersectionRatio ) {
				continue;
			}

			if ( !entry.target ) {
				return;
			}

			if ( !( 'render' in entry.target ) ) {
				return;
			}

			entry.target.render();
			tableauIntersectionObserver.unobserve( entry.target );
		}
	} );
}

class MrTableau extends HTMLElement {
	static get observedAttributes() {
		return [];
	}

	constructor() {
		// If you define a constructor, always call super() first!
		// This is specific to CE and required by the spec.
		super();
	}

	// Life cycle
	async connectedCallback() {
		this.classList.add( 'is-loading' );

		// TODO : add visible notice in html if loading fails at this point
		// Not implementing a catch as this code will let errors end up in bugsnag
		await loadTableauJS();

		if ( !tableauIntersectionObserver ) {
			this.render();

			return;
		}

		tableauIntersectionObserver.observe( this );
	}

	disconnectedCallback() {
		if ( !this.viz ) {
			return;
		}

		this.viz.dispose();
		this.classList.remove( 'is-loading' );
		this.classList.remove( 'is-interactive' );
	}

	setModifierClassIfOversized() {
		const iframe = this.querySelector( 'iframe' );
		if ( iframe && iframe.clientWidth > this.clientWidth ) {
			this.classList.add( 'is-oversized' );
		}
	}

	render() {
		const onFirstInteractive = () => {
			this.setModifierClassIfOversized();
			this.classList.remove( 'is-loading' );
			this.classList.add( 'is-interactive' );
		};

		const tableauView = this.getAttribute( 'tableau-view' );
		if ( !tableauView ) {
			return;
		}

		if ( this.viz ) {
			return;
		}

		this.viz = new window.tableau.Viz(
			this,
			'https://public.tableau.com/views/' + tableauView + '?:embed=yes&:toolbar=no&:record_performance=no&:refresh=no&:showAppBanner=false&:showVizHome=no',
			{
				onFirstInteractive: onFirstInteractive,
			}
		);
	}
}

customElements.define( 'mr-tableau', MrTableau );
