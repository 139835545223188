import { siteLang } from '../../../../helpers/translations.js';

export class MrSlide extends HTMLElement {
	constructor( controller, index, data ) {
		// If you define a constructor, always call super() first!
		// This is specific to CE and required by the spec.
		super();

		this.controller = controller;
		this.index = index;
		this.data = data;

		this.exited = () => {
			console.warn( 'MrSlide.exited not implemented' );
		};

		this.entered = () => {
			console.warn( 'MrSlide.entered not implemented' );
		};

		this.exitListener = () => {
			this.unbind();
			this.addEventListener( 'transitionend', this.exitedAnimationHandler );
			this.animating = true;

			// fallback for not transitionend browsers
			this.fallbackTimeout = setTimeout( () => {
				if ( this.animating ) {
					this.exitedAnimationComplete();
				}
			}, 800 );
		};

		this.exitedAnimationHandler = ( e ) => {
			if ( e.target === this ) {
				this.exitedAnimationComplete();
			}
		};

		this.exitedAnimationComplete = () => {
			clearTimeout( this.fallbackTimeout );
			this.animating = false;
			this.removeEventListener( 'transitionend', this.exitedAnimationHandler );

			this.controller.removeChild( this );
			this.exited();
		};

		this.enterHandler = () => {
			this.checkDescriptionLength();
			this.bind();
			this.animating = false;
			this.controller.classList.remove( 'is-animating' );
			this.entered();
		};

		this.descriptionToggleHandler = () => {
			if ( !this.description ) {
				return;
			}

			/* Initial height based on single line of text (.type-i) */
			let descriptionInnerInitialHeight = 19;

			if ( window.matchMedia( '(min-width: 1024px)' ).matches ) {
				descriptionInnerInitialHeight = 23;
			}

			if ( this.descriptionToggle.checked ) {
				// checkbox is checked, animate up
				let transform = `translateY(-${this.descriptionInner.offsetHeight - descriptionInnerInitialHeight}px)`;

				// Don't exceed max-height of description container
				if ( this.descriptionInner.offsetHeight > this.itemBottom.offsetHeight ) {
					transform = `translateY(-${this.itemBottom.offsetHeight - 48}px)`;
				}

				Object.assign( this.itemTopAssetContainer.style, {
					webkitTransform: transform,
					msTransform: transform,
					transform: transform,
				} );
			} else if ( this.itemTopAssetContainer.hasAttribute( 'style' ) ) {
				// checkbox is unchecked, remove style
				this.itemTopAssetContainer.removeAttribute( 'style' );
			}
		};
	}

	connectedCallback() {}

	disconnectedCallback() {}

	get description() {
		if ( 'undefined' === typeof( this.data.description ) || '' === this.data.description ) {
			return false;
		}

		return this.data.description;
	}

	bind() {
		if ( !this.description ) {
			return;
		}

		/* Move asset up/down on toggle */
		this.descriptionToggle.addEventListener( 'change', this.descriptionToggleHandler );
	}

	unbind() {
		if ( !this.description ) {
			return;
		}

		this.descriptionToggle.removeEventListener( 'change', this.descriptionToggleHandler );
	}

	enter() {
		this.controller.appendChild( this );
		this.enterHandler();
	}

	exitLeft() {
		this.controller.classList.add( 'is-animating' );
		this.exitListener();
	}

	exitRight() {
		this.controller.classList.add( 'is-animating' );
		this.exitListener();
	}

	checkDescriptionLength() {
		if ( !this.description ) {
			return;
		}

		// 1024px = 4.125rem
		if ( window.matchMedia( '(min-width: 1024px)' ).matches && !( 66 >= this.descriptionEl.offsetHeight ) ) {
			this.toggleToReadMore();

			return;
		}

		// 768px = 3.625rem
		if ( window.matchMedia( '(min-width: 768px) and (max-width: 1023px)' ).matches && !( 58 >= this.descriptionEl.offsetHeight ) ) {
			this.toggleToReadMore();

			return;
		}

		// < 768px = 3.375rem
		if ( window.matchMedia( '(max-width: 767px)' ).matches && !( 54 >= this.descriptionEl.offsetHeight ) ) {
			this.toggleToReadMore();

			return;
		}
	}

	toggleToReadMore() {
		this.itemBottom.className = 'gallery__item__bottom';
		this.descriptionToggle.checked = false;
	}

	render() {
		this.innerHTML = '';
		this.className = 'gallery__item';

		if ( this.description ) {
			this.descriptionToggle = document.createElement( 'input' );
			this.descriptionToggle.type = 'checkbox';
			this.descriptionToggle.id = 'show-full-asset-description';
			this.descriptionToggle.checked = true;
			this.descriptionToggle.className = 'gallery__item__description-checkbox';
			this.appendChild( this.descriptionToggle );
		}

		const itemTop = document.createElement( 'div' );
		itemTop.className = 'gallery__item__top';
		this.appendChild( itemTop );

		this.itemTopAssetContainer = document.createElement( 'div' );
		this.itemTopAssetContainer.className = 'gallery__item__asset-container';
		itemTop.appendChild( this.itemTopAssetContainer );

		if ( this.data.src ) {
			const asset = this.renderAsset();
			this.itemTopAssetContainer.appendChild( asset );
		}

		this.itemBottom = document.createElement( 'div' );
		this.itemBottom.className = 'gallery__item__bottom gallery__item__bottom--no-read-more-needed';
		this.appendChild( this.itemBottom );

		if ( this.description ) {
			/* Description */
			const itemBottomDescriptionContainer = document.createElement( 'div' );
			itemBottomDescriptionContainer.className = 'gallery__item__description-scroll-container';
			this.itemBottom.appendChild( itemBottomDescriptionContainer );

			this.descriptionEl = document.createElement( 'div' );
			this.descriptionEl.className = 'gallery__item__description';

			this.descriptionInner = document.createElement( 'div' );
			this.descriptionInner.className = 'gallery__item__description__inner';
			this.descriptionInner.innerHTML = this.description;
			this.descriptionEl.appendChild( this.descriptionInner );

			itemBottomDescriptionContainer.appendChild( this.descriptionEl );

			let readMoreLabel = 'Lees meer';
			let readLessLabel = 'Lees minder';

			if ( 'en' === siteLang() ) {
				readMoreLabel = 'Read more';
				readLessLabel = 'Read less';
			}

			/* Toggle label */
			const descriptionToggleLabel = document.createElement( 'label' );
			descriptionToggleLabel.setAttribute( 'for', 'show-full-asset-description' );
			descriptionToggleLabel.className = 'gallery__item__description-toggle';
			descriptionToggleLabel.innerHTML = `<span class="gallery__item__description-toggle__open">${readMoreLabel}</span><span class="gallery__item__description-toggle__close">${readLessLabel}</span>`;
			this.descriptionInner.appendChild( descriptionToggleLabel );
		}

		this.animating = false;
		this.controller.classList.remove( 'is-animating' );
	}

	renderAsset() {
		const quality = 60;
		const bg = '000000';
		const dpr = window.devicePixelRatio ?? 1;
		let max_width = 1096;
		let max_height = 832;
		const current_width = window.innerWidth;
		const current_height = window.innerHeight;

		// calculate max_height
		// --------------------
		// max_height calculation starts off when the max_height isn't in control:
		// Data from CSS: calc(100% - 6.25rem)
		// Which means: 832 + 100 = 932
		if ( window.matchMedia( '(max-height: 931px)' ).matches ) {
			// data from css: calc(100% - 6.25rem)
			max_height = current_height - 100;
		}

		// calculate max_width
		// -------------------
		// max_width calculation starts off when the max_width isn't in control:
		// Data from CSS: calc(100% - 6rem)
		// Which means: 1096 + 96 = 1352
		if ( window.matchMedia( '(max-width: 1351px)' ).matches ) {
			// data from css: calc(100% - 16rem)
			max_width = current_width - 256;

			if ( window.matchMedia( '(max-width: 1023px)' ).matches ) {
				// data from css: calc(100% - 10rem)
				max_width = current_width - 160;
			}

			if ( window.matchMedia( '(max-width: 767px)' ).matches ) {
				// data from css: calc(100% - 6rem)
				max_width = current_width - 96;
			}
		}

		// Build asset and return
		const asset = document.createElement( 'img' );
		asset.className = 'gallery__item__asset';

		// Check when loaded if the asset width is larger then max_width so
		// we can contain the image (Fixes IE11)
		asset.onload = () => {
			if ( max_width <= asset.width ) {
				asset.className = 'gallery__item__asset gallery__item__asset--fill-horizontal';
			}
		};

		asset.src = `${this.data.src}?w=${max_width}&h=${max_height}&fit=max&auto=format&q=${quality}&bg=${bg}&dpr=${dpr}`;

		return asset;
	}
}

customElements.define( 'mr-slide', MrSlide );
