import { State, Chapter, Question, ChecklistAction } from '../types/index';

export function checklistReducer(
	state: State | undefined = {
		chapters: [],
	},
	action: ChecklistAction
): State {
	// Get the index for the chapter in the action.
	const chapterStateIndex = state.chapters.findIndex( ( chapter: Chapter ) => {
		return chapter.chapterId === action.chapterId;
	} );

	// Get the index for the question in the chapter in the action.
	const currentChapterQuestions = state.chapters[chapterStateIndex]?.questions;
	const questionStateIndex = currentChapterQuestions?.findIndex( ( question: Question ) => {
		if (
			'TOGGLE_QUESTION' !== action.type &&
			'TOGGLE_ANSWER' !== action.type
		) {
			return false;
		}

		return question.questionId === action.questionId;
	} );

	// Get the id for the answer in the question in the chapter in the action.
	const currentQuestionAnswer = state.chapters[chapterStateIndex]?.questions[questionStateIndex]?.answerId;

	switch ( action.type ) {
		case 'TOGGLE_CHAPTER':
			// Add chapter if not yet in state.
			if ( -1 === chapterStateIndex ) {
				return {
					...state,
					chapters: [
						...state.chapters,
						{
							chapterId: action.chapterId,
							open: true,
							progress: 0,
							questions: [],
						},
					],
				};
			}

			// Update existing chapter.
			return {
				...state,
				chapters: [
					...state.chapters.slice( 0, chapterStateIndex ),
					chapterActionReducer( state.chapters[chapterStateIndex], action ),
					...state.chapters.slice( chapterStateIndex + 1 ),
				],
			};

		case 'TOGGLE_QUESTION':
			// Add question if not yet in state.
			if ( 0 > questionStateIndex ) {
				return {
					...state,
					chapters: [
						...state.chapters.slice( 0, chapterStateIndex ),
						{
							...state.chapters[chapterStateIndex],
							questions: [
								...state.chapters[chapterStateIndex].questions,
								{
									answerId: '',
									chapterId: action.chapterId,
									done: false,
									questionId: action.questionId,
									open: true,
								},
							],
						},
						...state.chapters.slice( chapterStateIndex + 1 ),
					],
				};
			}

			// Update existing question.
			return {
				...state,
				chapters: [
					...state.chapters.slice( 0, chapterStateIndex ),
					{
						...state.chapters[chapterStateIndex],
						questions: [
							...state.chapters[chapterStateIndex].questions.slice( 0, questionStateIndex ),
							questionActionReducer( state.chapters[chapterStateIndex].questions[questionStateIndex], action ),
							...state.chapters[chapterStateIndex].questions.slice( questionStateIndex + 1 ),
						],
					},
					...state.chapters.slice( chapterStateIndex + 1 ),
				],
			};

		case 'TOGGLE_ANSWER':
			// Add answer if not yet in state.
			if ( !currentQuestionAnswer ) {
				return {
					...state,
					chapters: [
						...state.chapters.slice( 0, chapterStateIndex ),
						{
							...state.chapters[chapterStateIndex],
							progress: state.chapters[chapterStateIndex].progress + 1,
							questions: [
								...state.chapters[chapterStateIndex].questions.slice( 0, questionStateIndex ),
								{
									...state.chapters[chapterStateIndex].questions[questionStateIndex],
									answerId: action.answerId,
									done: true,
								},
								...state.chapters[chapterStateIndex].questions.slice( questionStateIndex + 1 ),
							],
						},
						...state.chapters.slice( chapterStateIndex + 1 ),
					],
				};
			}

			// Remove current answer for question.
			if ( currentQuestionAnswer === action.answerId ) {
				return {
					...state,
					chapters: [
						...state.chapters.slice( 0, chapterStateIndex ),
						{
							...state.chapters[chapterStateIndex],
							progress: state.chapters[chapterStateIndex].progress - 1,
							questions: [
								...state.chapters[chapterStateIndex].questions.slice( 0, questionStateIndex ),
								{
									...state.chapters[chapterStateIndex].questions[questionStateIndex],
									done: false,
									answerId: '',
								},
								...state.chapters[chapterStateIndex].questions.slice( questionStateIndex + 1 ),
							],
						},
						...state.chapters.slice( chapterStateIndex + 1 ),
					],
				};
			}

			// Update current answer for question.
			return {
				...state,
				chapters: [
					...state.chapters.slice( 0, chapterStateIndex ),
					{
						...state.chapters[chapterStateIndex],
						questions: [
							...state.chapters[chapterStateIndex].questions.slice( 0, questionStateIndex ),
							{
								...state.chapters[chapterStateIndex].questions[questionStateIndex],
								done: true,
								answerId: action.answerId,
							},
							...state.chapters[chapterStateIndex].questions.slice( questionStateIndex + 1 ),
						],
					},
					...state.chapters.slice( chapterStateIndex + 1 ),
				],
			};

		default:
			return state;
	}
}

function chapterActionReducer( chapter: Chapter, action: ChecklistAction ): Chapter {
	if ( 'TOGGLE_CHAPTER' !== action.type ) {
		throw new Error( 'invalid action: ' + action.type );
	}

	return {
		...chapter,
		open: !chapter.open,
	};
}

function questionActionReducer( question: Question, action: ChecklistAction ): Question {
	if ( 'TOGGLE_QUESTION' !== action.type ) {
		throw new Error( 'invalid action: ' + action.type );
	}

	return {
		...question,
		open: !question.open,
	};
}
