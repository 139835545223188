import { MrSlidesController } from './slides-controller.js';
import { MrSlide } from './slide.js';
import { checkSmallTouchDevice } from '@mrhenry/wp--check-small-touch-device';

class MrGallery extends MrSlidesController {
	// Attributes

	prepareSlides() {
		if ( !this.data || !this.data.length ) {
			return;
		}

		// check device
		this.isMobile = checkSmallTouchDevice();
		this.slides = [];

		let previousSlide = null;
		for ( let i = 0; i < this.data.length; i++ ) {
			const slideData = this.data[i];
			const slide = new MrSlide( this, i, slideData );

			if ( previousSlide ) {
				previousSlide.nextSlide = slide;
				slide.previousSlide = previousSlide;
			}

			if ( i === ( this.data.length - 1 ) ) {
				this.slides[0].previousSlide = slide;
				slide.nextSlide = this.slides[0];
			}

			previousSlide = slide;

			this.slides[i] = slide;
		}
	}

	slideAtIndex( index ) {
		if ( !this.slides || !this.slides.length || index >= this.slides.length ) {
			return;
		}

		return this.slides[index];
	}

	goTo( indexer, oldIndex, newIndex ) {
		if ( null === newIndex ) {
			this.hide();

			return;
		}

		if ( null === oldIndex ) {
			this.show();
		}

		indexer.disabled = true;

		const slide = this.slideAtIndex( newIndex );
		if ( !slide ) {
			indexer.disabled = false;

			return;
		}

		slide.render();

		slide.entered = () => {
			indexer.disabled = false;
		};

		const enter = () => {
			this.currentSlide = slide;
			this.currentSlide.enter();
		};

		if ( !this.currentSlide ) {
			enter();

			return;
		}

		const exit = () => {
			if ( ( oldIndex === ( newIndex - 1 ) || oldIndex > ( newIndex + 1 ) ) ) {
				this.currentSlide.exitLeft();

				return;
			}

			this.currentSlide.exitRight();
		};

		this.currentSlide.exited = () => {
			enter();
		};

		exit();
	}

	hide() {
		this.classList.remove( 'is-open' );
		document.body.classList.remove( 'has-open-gallery' );

		for ( let i = 0; i < this.slides.length; i++ ) {
			const slide = this.slides[i];

			if ( slide.parentNode ) {
				slide.parentNode.removeChild( slide );
			}
		}

		this.updateControls( false );
		this.currentSlide = false;
		this.prepareSlides();
	}

	show() {
		this.updateControls( true );
		this.classList.add( 'is-open' );
		document.body.classList.add( 'has-open-gallery' );
	}

	updateControls( enabled ) {
		for ( let i = 0; i < this.children.length; i++ ) {
			if ( enabled ) {
				this.children[i].removeAttribute( 'disabled' );
			} else {
				this.children[i].setAttribute( 'disabled', true );
			}
		}
	}
}

customElements.define( 'mr-gallery', MrGallery );
