( function() {
	let init = () => {
		if ( 'interactive' !== document.readyState && 'complete' !== document.readyState ) {
			return;
		}

		const body = document.body;
		const scrollWaypoints = Array.from( document.querySelectorAll( 'mr-top-scroll-waypoint' ) );

		if ( !body || !scrollWaypoints || 1 > scrollWaypoints.length ) {
			return;
		}

		const lastScrollWaypoint = scrollWaypoints[scrollWaypoints.length - 1];

		if ( !lastScrollWaypoint ) {
			return;
		}

		init = () => { };

		// Weeee modern browser
		// Check top of page
		if ( 'IntersectionObserver' in window ) {
			const observer = new IntersectionObserver( ( entries ) => {
				for ( let i = 0; i < entries.length; i += 1 ) {
					const entry = entries[i];

					if ( !entry.isIntersecting ) {
						if ( body.classList && !body.classList.contains( 'is-scrolled' ) ) {
							body.classList.add( 'is-scrolled' );
						}
					} else if ( body.classList && body.classList.contains( 'is-scrolled' ) ) {
						body.classList.remove( 'is-scrolled' );
					}
				}
			} );

			observer.observe( lastScrollWaypoint );

			// Meh crappy browser
			// Make sure navigation elements are always readable
		} else if ( body.classList && !body.classList.contains( 'body--has-no-scroll-watcher' ) ) {
			body.classList.add( 'body--has-no-scroll-watcher' );
		}
	};

	document.addEventListener( 'readystatechange', () => {
		init();
	}, false );

	init();
}() );
