class MrBlockedContent extends HTMLElement {
	constructor() {
		/** If you define a constructor, always call super() first!
		 * This is specific to CE and required by the spec.
		 */
		super();

		this.clickHandler = ( e ) => {
			if ( !e.target ) {
				return;
			}

			if ( !e.target.hasAttribute( 'unblocks-content' ) ) {
				return;
			}

			e.preventDefault();

			this.unblockGroup();
			this.show();

			this.showOthersInGroup();
		};
	}

	show() {
		window.requestAnimationFrame( () => {
			const contentStr = this.getAttribute( 'content' );
			this.removeAttribute( 'content' );

			if ( !contentStr ) {
				return;
			}

			const content = JSON.parse( contentStr );

			if ( !content || !content.html ) {
				return;
			}

			this.innerHTML = content.html;
			this.setAttribute( 'data-state', 'unblocked' );
		} );
	}

	showOthersInGroup() {
		let group = this.getAttribute( 'group' );

		if ( !group ) {
			group = ( new Date() ).toString();
		}

		const elements = Array.from( document.querySelectorAll( `mr-blocked-content[group="${group}"]` ) );

		elements.forEach( ( element ) => {
			if ( !element.show ) {
				return;
			}

			element.show();
		} );
	}

	unblockGroup() {
		const group = this.getAttribute( 'group' );

		if ( !group ) {
			return;
		}

		try {
			let alreadyUnblockedStr = window.sessionStorage.getItem( 'unblocked-content' );
			let alreadyUnblocked = {};

			if ( alreadyUnblockedStr ) {
				alreadyUnblocked = JSON.parse( alreadyUnblockedStr );
			}

			if ( alreadyUnblocked && alreadyUnblocked[group] ) {
				// Do nothing, already unblocked
				return;
			}

			// Add group to unblocked content
			alreadyUnblocked[group] = {
				unblockedAt: new Date(),
				unblockedFor: 'session',
			};

			alreadyUnblockedStr = JSON.stringify( alreadyUnblocked );
			window.sessionStorage.setItem( 'unblocked-content', alreadyUnblockedStr );
		} catch ( error ) {
			console.warn( error );
		}
	}

	checkGroupIsUnblocked() {
		const group = this.getAttribute( 'group' );

		if ( !group ) {
			return false;
		}

		try {
			const alreadyUnblockedStr = window.sessionStorage.getItem( 'unblocked-content' );

			if ( !alreadyUnblockedStr ) {
				return false;
			}

			const alreadyUnblocked = JSON.parse( alreadyUnblockedStr );

			if ( alreadyUnblocked && alreadyUnblocked[group] ) {
				return true;
			}
		} catch ( error ) {
			console.warn( error );

			return false;
		}

		return false;
	}

	// Life cycle
	connectedCallback() {
		window.requestAnimationFrame( () => {
			if ( this.checkGroupIsUnblocked() ) {
				this.show();

				return;
			}

			this._addEventListeners();
		} );
	}

	disconnectedCallback() {
		this._removeEventListeners();
	}

	_addEventListeners() {
		this.addEventListener( 'click', this.clickHandler, {
			once: true,
		} );
	}

	_removeEventListeners() {
		this.removeEventListener( 'click', this.clickHandler );
	}
}

customElements.define( 'mr-blocked-content', MrBlockedContent );
