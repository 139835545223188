/*
 * Use intersection observer to show and hide the sticky bookmark,
 * based on the visibility of the header title and site footer.
 */

( function() {
	let init = () => {
		if ( 'interactive' !== document.readyState && 'complete' !== document.readyState ) {
			return;
		}

		const body = document.body;
		const headerTitle = document.querySelector( '.js-header-title' );
		const siteFooter = document.querySelector( '.js-site-footer' );
		const stickyBookmark = document.querySelector( '.js-sticky-bookmark' );

		const scrollClass = 'is-scrolled-between-header-and-footer';

		let headerTitleVisible = true;
		let siteFooterVisible = true;

		if ( !body ) {
			return;
		}

		if ( !stickyBookmark ) {
			return;
		}

		if ( window.matchMedia( '(max-height: 540px)' ).matches ) {
			return;
		}

		if ( window.matchMedia( '(max-width: 1279px)' ).matches ) {
			return;
		}

		init = () => {};

		const addScrollClass = () => {
			/*
			 * Only show sticky bookmark of neither header title and
			 * site footer are visible.
			 */
			if (
				body.classList &&
				!body.classList.contains( scrollClass ) &&
				!headerTitleVisible &&
				!siteFooterVisible
			) {
				body.classList.add( scrollClass );
			}
		};

		const removeScrollClass = () => {
			if ( body.classList && body.classList.contains( scrollClass ) ) {
				body.classList.remove( scrollClass );
			}
		};

		/* Sticky Bookmark wil never show for older browsers */
		if ( 'IntersectionObserver' in window ) {
			if ( headerTitle ) {
				const headerTitleObserver = new IntersectionObserver( ( entries ) => {
					for ( let i = 0; i < entries.length; i += 1 ) {
						const entry = entries[i];

						if ( entry.isIntersecting ) {
							headerTitleVisible = true;
							removeScrollClass();
						} else {
							headerTitleVisible = false;
							addScrollClass();
						}
					}
				} );

				headerTitleObserver.observe( headerTitle );
			}

			if ( siteFooter ) {
				const siteFooterObserver = new IntersectionObserver( ( entries ) => {
					for ( let i = 0; i < entries.length; i += 1 ) {
						const entry = entries[i];

						if ( entry.isIntersecting ) {
							siteFooterVisible = true;
							removeScrollClass();
						} else {
							siteFooterVisible = false;
							addScrollClass();
						}
					}
				} );

				siteFooterObserver.observe( siteFooter );
			}
		}
	};

	document.addEventListener( 'readystatechange', () => {
		init();
	}, false );

	init();
}() );
