class MrGoToIndex extends HTMLElement {
	static get observedAttributes() {
		return [
			'disabled',
			'for',
			'value',
		];
	}

	constructor() {
		// If you define a constructor, always call super() first!
		// This is specific to CE and required by the spec.
		super();

		// Event Handlers
		this.click = ( e ) => {
			if ( this.disabled ) {
				return;
			}

			e.preventDefault();
			this.goTo( 0 );
		};
	}

	// Life cycle
	connectedCallback() {
		this._addEventListeners();
	}

	disconnectedCallback() {
		this._removeEventListeners();
	}

	// Attributes
	get disabled() {
		return this.hasAttribute( 'disabled' );
	}

	set disabled( val ) {
		if ( val ) {
			this.setAttribute( 'disabled', '' );
		} else {
			this.removeAttribute( 'disabled' );
		}
	}

	get forEl() {
		const forID = this.getAttribute( 'for' );
		if ( !forID ) {
			return null;
		}

		return document.getElementById( forID );
	}

	attributeChangedCallback( attrName, oldVal, newVal ) {
		if ( 'disabled' === attrName ) {
			if ( null === newVal ) {
				this._addEventListeners();
			} else {
				this._removeEventListeners();
			}

			return;
		}

		if ( 'value' === attrName ) {
			if ( !this.disabled ) {
				this._addEventListeners();
			}

			return;
		}
	}

	_addEventListeners() {
		this.galleryLinks = document.querySelectorAll( `.js-gallery__trigger[for=${this.getAttribute( 'value' )}]` );

		for ( let i = 0; i < this.galleryLinks.length; i++ ) {
			this.galleryLinks[i].addEventListener( 'click', this.click );
		}
	}

	_removeEventListeners() {
		for ( let i = 0; i < this.galleryLinks.length; i++ ) {
			this.galleryLinks[i].removeEventListener( 'click', this.click );
		}
	}

	goTo( index ) {
		const forEl = this.forEl;
		if ( forEl ) {
			forEl.setAttribute( 'value', index );
		}
	}
}

customElements.define( 'mr-go-to-index', MrGoToIndex );
