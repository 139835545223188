import { State, Chapter, Question } from '../types/index';

export function renderUpdate( state: State ): void {
	if ( !state || !state.chapters ) {
		return;
	}

	// Toggle chapters.
	state.chapters.forEach( ( chapter: Chapter ) => {
		if ( !chapter.chapterId ) {
			return;
		}

		const currentChapter = document.getElementById( chapter.chapterId );

		if ( !currentChapter ) {
			return;
		}

		if ( chapter.open ) {
			currentChapter.setAttribute( 'data-toggled', 'true' );
		} else {
			currentChapter.removeAttribute( 'data-toggled' );
		}

		// Update chapter progress label.
		const currentChapterProgressLabel = document.getElementById( `progress-label-${chapter.chapterId}` );

		if ( currentChapterProgressLabel && ( currentChapterProgressLabel instanceof HTMLElement ) ) {
			currentChapterProgressLabel.innerHTML = chapter.progress.toString();
		}

		// Update chapter progress.
		const currentChapterProgress = currentChapter.querySelector( 'progress' );

		if ( currentChapterProgress && ( currentChapterProgress instanceof HTMLElement ) ) {
			currentChapterProgress.setAttribute( 'value', chapter.progress.toString() );

			if (
				currentChapterProgress.hasAttribute( 'max' ) &&
				currentChapterProgress.getAttribute( 'max' ) === chapter.progress.toString()
			) {
				currentChapter.setAttribute( 'data-done', 'true' );
			} else {
				currentChapter.removeAttribute( 'data-done' );
			}
		}

		if ( !chapter.questions ) {
			return;
		}

		// Toggle questions in chapter.
		chapter.questions.forEach( ( question: Question ) => {
			if ( !question.questionId ) {
				return;
			}

			const currentQuestion = document.getElementById( question.questionId );

			if ( !currentQuestion || !( currentQuestion instanceof HTMLElement ) ) {
				return;
			}

			if ( question.open ) {
				currentQuestion.setAttribute( 'data-toggled', 'true' );
			} else {
				currentQuestion.removeAttribute( 'data-toggled' );
			}

			if ( question.done ) {
				currentQuestion.setAttribute( 'data-done', 'true' );
			} else {
				currentQuestion.removeAttribute( 'data-done' );
			}

			// Uncheck the currently active answer.
			currentQuestion.querySelector( '[data-toggled]' )?.removeAttribute( 'data-toggled' );

			// Check the new answer.
			if ( question.answerId ) {
				document.getElementById( question.answerId )?.setAttribute( 'data-toggled', 'true' );
			}
		} );
	} );
}
