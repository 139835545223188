class MrSlidesIndex extends HTMLElement {
	static get observedAttributes() {
		return [
			'value',
		];
	}

	constructor() {
		// If you define a constructor, always call super() first!
		// This is specific to CE and required by the spec.
		super();
	}

	getAttribute( attr ) {
		if ( 'value' === attr ) {
			return this.extractIntValue( super.getAttribute( attr ) );
		}

		return super.getAttribute( attr );
	}

	setAttribute( attr, value ) {
		if ( this.disabled ) {
			return;
		}

		if ( 'value' === attr ) {
			const sanitized = this.sanitizeValueUpdate( value );

			if ( sanitized === this.value ) {
				return;
			}

			if ( null === sanitized ) {
				super.setAttribute( attr, '' );

				return;
			}

			super.setAttribute( attr, sanitized );

			return;
		}

		super.setAttribute( attr, value );
	}

	// Attributes
	get disabled() {
		return this.hasAttribute( 'disabled' );
	}

	set disabled( value ) {
		if ( value ) {
			this.setAttribute( 'disabled', '' );
		} else {
			this.removeAttribute( 'disabled' );
		}
	}

	get max() {
		return this.extractIntValue(
			this.getAttribute( 'max' )
		);
	}

	get value() {
		return this.getAttribute( 'value' );
	}

	set value( value ) {
		this.setAttribute( 'value', value );
	}

	get forEl() {
		const forID = this.getAttribute( 'for' );
		if ( !forID ) {
			return null;
		}

		return document.getElementById( forID );
	}

	get loop() {
		return this.hasAttribute( 'loop' );
	}

	attributeChangedCallback( attrName, oldVal, newVal ) {
		if ( 'value' === attrName ) {
			const newValue = this.extractIntValue( newVal );
			const oldValue = this.extractIntValue( oldVal );

			this.dispatchEvent( new Event( 'change', {
				bubbles: false,
				cancelable: false,
				composed: false,
			} ) );

			this.goTo( oldValue, newValue );

			return;
		}
	}

	goTo( oldVal, newVal ) {
		const forEl = this.forEl;
		if ( !forEl ) {
			return;
		}

		forEl.goTo( this, oldVal, newVal );

		return;
	}

	sanitizeValueUpdate( val ) {
		const value = this.extractIntValue( val );
		if ( null === value ) {
			return value;
		}

		if ( this.loop && value > this.max ) {
			return 0;
		}

		if ( value > this.max ) {
			return this.max;
		}

		if ( this.loop && 0 > value ) {
			return this.max;
		}

		if ( 0 > value ) {
			return 0;
		}

		return value;
	}

	extractIntValue( val ) {
		const value = parseInt( val, 10 );
		if ( isNaN( value ) ) {
			return null;
		}

		return value;
	}
}

customElements.define( 'mr-slides-index', MrSlidesIndex );
