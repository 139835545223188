import { createStore } from 'redux';
import { renderUpdate } from './helpers';
import { checklistReducer } from './reducers/checklist-reducer';

const store = createStore( checklistReducer );
store.subscribe( () => {
	// Listens for all changes on store.
	renderUpdate( store.getState() );
} );

window.addEventListener( 'click', ( e: MouseEvent ): void => {
	if ( !e || !e.target ) {
		return;
	}

	const clicked = e.target;
	if ( !( clicked instanceof HTMLElement ) ) {
		return;
	}

	const forChapter = clicked.getAttribute( 'for-chapter' );
	const forQuestion = clicked.getAttribute( 'for-question' );
	const forAnswer = clicked.getAttribute( 'for-answer' );

	if ( forChapter && forQuestion && forAnswer ) {
		store.dispatch( {
			type: 'TOGGLE_ANSWER',
			answerId: forAnswer,
			chapterId: forChapter,
			questionId: forQuestion,
		} );
	} else if ( forChapter && forQuestion ) {
		store.dispatch( {
			type: 'TOGGLE_QUESTION',
			chapterId: forChapter,
			questionId: forQuestion,
		} );
	} else if ( forChapter ) {
		store.dispatch( {
			type: 'TOGGLE_CHAPTER',
			chapterId: forChapter,
		} );
	}
} );
